<template>
  <div class="scroll_container" v-if="scrollList.length">
    <template v-for="(item, index) in scrollList" :key="item.id">
      <p class="slogan" v-if="active === index">
        <span @click.stop="toMessage(item)">
          <span>{{ item.name + ':' }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>{{ item.message }}</span>
        </span>
      </p>
    </template>
  </div>
</template>

<script>
export default { name: 'CJGScroll' };
</script>
<script setup>
import { getLatestCardsApi } from "@/api/index";
import { ref, defineEmits } from 'vue';
import Message from '@/components/CJG/message/message';

const emit = defineEmits( ['toItem'] );
const scrollList = ref( [] );
const active = ref( 0 );
let oldDate = '';

// 获取最新消息
const getLatestCardsFN = async () => {
  try {
    const { message } = await getLatestCardsApi( { number: 20 } );
    scrollList.value = message;
  } catch ( err ) {
    Message( { message: err, type: 'error' } );
  }
};

// 跳转到对应的留言详情 
const toMessage = ( item ) => {
  emit( 'toItem', item );
};

// 滚动到指定位置
const activeAdd = () => {
  if ( oldDate === '' || ( new Date().getTime() - oldDate ) > 5000 ) {
    active.value = active.value + 1 > scrollList.value.length - 1 ? 0 : active.value + 1;
    oldDate = new Date().getTime();
  }
  requestAnimationFrame( activeAdd );
};
// 使用requestAnimationFrame函数 递归调用，比定时器节省性能
requestAnimationFrame( activeAdd );

getLatestCardsFN();
</script>
<style lang="less" scoped>
.scroll_container {
  width: 100%;

  .slogan {
    transition: @tr;
    padding: 8px 20px 4px;
    color: @gray-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>