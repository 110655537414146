<template>
  <div class="wall-container" @click="onWallCloseModel">
    <div class="wall-scroll">
      <CJGScroll @toItem="onToItem"></CJGScroll>
    </div>
    <p class="title wall-title" v-if="!isMobile">{{ wallType[id].name }}</p>
    <p class="slogan" v-if="!isMobile">{{ wallType[id].slogan }}</p>
    <div class="label">
      <p class="label-item" :class="{ labelActive: labelActive === -1 }" @click="onLabelClick(-1)">全部</p>
      <p class="label-item" :class="{ labelActive: labelActive === index }" v-for="(item, index) in labels[id]"
        :key="index" @click="onLabelClick(index)">
        {{ item }}
      </p>
    </div>

    <div class="note-container" v-if="id == 0">
      <NoteCard v-for="item in cards" :key="item.id" :note="item" @clearCache="onClearCache"
        :class="{ noteActive: item.id === activeNote }" @toDetail="onNoteClick(item.id)">
      </NoteCard>
    </div>

    <div class="photo-container" v-if="id == 1">
      <PhotoCard class="photo-card" v-for="item in cards" :key="item.id" :photo="item" @clearCache="onClearCache"
        @toDetail="onNoteClick(item.id)"></PhotoCard>
    </div>

    <CJGShowDateStatus :isAnimation="isAnimation" :page="page" :length="cards.length"></CJGShowDateStatus>
    <CJGBtnAddRefresh :isRefresh="isRefresh" :addBottom="addBottom" :isModel="isModel" @onRefresh="onRefresh"
      @openModel="openModel"></CJGBtnAddRefresh>
    <CJGModel :title="title" @close="onWallCloseModel" :isModel="isModel" :articleUrl="articleUrl"
      :activeNote="activeNote">
      <NewCard :id="id" @onHandleClose="onWallCloseModel" v-if="activeNote == -1" @onSubmit="addNewCard"></NewCard>
      <CardDetail v-if="activeNote != -1" :news="news" :card="getCard" @clearCache="onClearCache"
        @waitDelete="waitDelete" @haveReportInfo="haveReportInfo" @alreadyDelete="onAlreadyDelete">
      </CardDetail>
    </CJGModel>

    <CJGViewer v-if="(id == 1 || news?.imgurl) && !isMobile" :isViewer="isViewer" :card="getCard" :newsImg="news.imgurl"
      @toggleViewer="onToggleViewer">
    </CJGViewer>
  </div>
</template>

<script>
import NoteCard from "@/components/NoteCard.vue";
import CJGModel from "@/components/CJGModel.vue";
import NewCard from "@/components/NewCard.vue";
import CardDetail from "@/components/CardDetail.vue";
import PhotoCard from "@/components/PhotoCard.vue";
import CJGViewer from "@/components/CJGViewer.vue";
import CJGScroll from "@/components/CJGScroll.vue";
import CJGShowDateStatus from "@/components/CJGShowDataStatus.vue";
import CJGBtnAddRefresh from "@/components/CJGBtnAddRefresh.vue";
import { removeNotes, removePhotos } from "@/store/sessionStorage";
import { wallType, labels } from "@/utils/data";
// import { photo, note } from "/mock/index";
import { findWallPageApi, findWallPageByIdApi } from '@/api/index';
import { setNotes, setPhotos, getNotes, getPhotos } from '@/store/sessionStorage';
import eventBus from "@/utils/eventBus";
import { throttle } from '@/utils/handleFn';
import { shareUrl } from '@/utils/env';

export default {
  components: {
    NoteCard,
    CJGModel,
    NewCard,
    CardDetail,
    PhotoCard,
    CJGViewer,
    CJGScroll,
    CJGShowDateStatus,
    CJGBtnAddRefresh,
  },
  data () {
    return {
      wallType,
      // note: note.data, // 留言数据
      // photo: photo.data, // 图片数据
      labels,
      labelActive: -1,
      cards: [], // 留言或图片
      news: {}, // 滚动条的单项数据
      storeState: this.$store.state,
      addBottom: 40, //添加留言按钮距离底部距离
      title: "写留言", // model标题
      modelVisible: false,
      isModel: false, //model是否显示
      isViewer: false, // 是否显示图片预览
      activeNote: -1, // 当前选中卡片 -1 为未选中
      isAnimation: -1, // 动画 -1 加载中 0 数据为空 1 无更多数据(加载完成)
      page: 1,
      pageSize: 15,
      isRefresh: false, // 是否刷新
      id: 0, //留言墙与照片墙的切换id
      lastOperationTime: 0,
      cardItem: {}, // 选中的文章
      articleUrl: '', // 选中文章的链接
    };
  },
  watch: {
    activeNote () {
      if ( this.activeNote == -1 ) {
        this.title = "写留言";
      } else {
        this.title = "";
      }
    },
    // 墙id
    id () {
      this.activeNote = -1;
      this.labelActive = -1;
      this.isModel = false;
      this.isViewer = false;
      this.page = 1;
      this.cards = [];
      this.cardItem = {};
      this.onCheckCachedData();
      document.body.style.overflowY = 'auto';
    },
    '$route.query.id' ( nv ) {
      if ( isNaN( nv ) ) return;
      this.id = Number( nv );
    }
  },
  computed: {
    modeHeight () {
      return this.storeState && this.storeState.isMobile ? 200 : 250;
    },
    isMobile () {
      return this.storeState && this.storeState.isMobile;
    },
    // 获取墙数据
    getCard () {
      if ( Object.keys( this.cardItem ).length > 0 ) {
        return this.cardItem;
      } else {
        return this.cards.find( ( v ) => Number( v.id ) === Number( this.activeNote ) );
      }
    },
    // 用户id
    userId () {
      return this.storeState.user?.id || this.storeState?.ip || '0.0.0.0';
    },
  },
  created () {
    this.id = Number( this.$route.query.id ) || 0;
    this.onCheckCachedData();
    this.isMobile ? this.addBottom = 100 : this.addBottom = 40;
  },
  mounted () {
    window.addEventListener( "scroll", this.onScrollBottom );
    this.isMobile && eventBus.$on( 'onWallCloseModel', this.onWallCloseModel );
    this.externalOpenModel();
  },
  unmounted () {
    window.removeEventListener( "scroll", this.onScrollBottom );
    this.isMobile && eventBus.$off( 'onWallCloseModel', this.onWallCloseModel );
  },
  methods: {
    /** 外部打开弹窗 */
    async externalOpenModel () {
      const cardId = this.$route.params.cardId;
      if ( !cardId ) return;
      this.activeNote = Number( cardId );
      this.cardItem = this.cards.find( item => Number( item.cardId ) === Number( cardId ) );
      if ( !this.cardItem ) {
        const result = await findWallPageByIdApi( { id: this.activeNote, type: this.id } );
        if ( result?.message && Object.keys( result.message ).length > 0 ) this.cardItem = result.message;
      }
      this.$nextTick( () => {
        if ( this.cardItem ) {
          this.isModel = true;
          this.isViewer = !!this.id;
          this.copyShareUrl();
        } else {
          this.$message( { type: 'warning', message: '内容未找到~' } );
        }
      } );
      this.$router.replace( { path: '/wall', query: { id: this.id } } ); // 路由重定向
    },
    copyShareUrl () {
      this.articleUrl = `${ shareUrl }/#/wall/${ this.activeNote }?id=${ this.id }`;
    },
    // 关闭Model弹窗
    onWallCloseModel ( data ) {
      this.isModel = false;
      this.isViewer = false;
      this.activeNote = -1;
      this.news = {};
      this.cardItem = {};
      document.body.style.overflowY = 'auto';
      if ( !data );
      this.$store.commit( 'setSystemRouteStack', { type: 0 } );
    },

    // 切换弹窗
    onNoteClick ( i ) {
      this.cardItem = {};
      if ( this.activeNote != i ) {
        this.activeNote = i;
        if ( typeof i == 'object' ) {
          if ( i.imgurl != '' ) this.isViewer = true;
        } else {
          if ( this.id == 1 ) this.isViewer = true;
        }
        this.isModel = true;
      } else {
        this.activeNote = -1;
        if ( this.id == 1 ) this.isViewer = false;
        this.isModel = false;
      }
      this.copyShareUrl();
      this.$store.commit( 'setSystemRouteStack', { type: 1, systemRoute: 'onWallCloseModel' } );
    },

    // 切换标签
    onLabelClick ( i ) {
      this.labelActive = i;
      this.cards = [];
      this.page = 1;
      this.onCheckCachedData();
    },

    // 切换图片预览
    onToggleViewer ( direction ) {
      let index = this.cards.findIndex( item => item.id == this.activeNote );
      let lengths = this.cards.length - 1;
      if ( direction == 0 ) {
        index == 0 ? index = lengths : index--;
      } else {
        index == lengths ? index = 0 : index++;
      }
      this.activeNote = this.cards[index].id;
      this.copyShareUrl();
    },

    openModel () {
      this.isModel = !this.isModel;
      this.activeNote = -1;
      this.$store.commit( 'setSystemRouteStack', { type: 1, systemRoute: 'onWallCloseModel' } );
    },

    // 监听页面滚动
    onScrollBottom () {
      // 距离顶部距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 视口高度
      let clientHeight = document.documentElement.clientHeight;
      // 内容高度
      let scrollHeight = document.documentElement.scrollHeight;
      if ( this.isMobile ) {
        this.addBottom = 100;
      } else {
        if ( scrollTop + clientHeight + this.modeHeight >= scrollHeight ) {
          this.addBottom = scrollTop + clientHeight + this.modeHeight - scrollHeight;
        } else {
          this.addBottom = 40;
        }
      }
      // 加载更多卡片
      if ( Math.ceil( scrollTop + clientHeight ) + 150 >= scrollHeight ) {
        this.getWallCard();
      }
    },

    addNewCard () {
      this.cards = [];
      this.page = 1;
      this.getWallCard();
      this.onWallCloseModel();
    },

    // 检查缓存是否有对应数据 - 从缓存中获取
    onCheckCachedData () {
      let result = this.id == 0 ? getNotes( this.labelActive ) : getPhotos( this.labelActive );
      if ( result && Array.isArray( result.data ) && typeof result.page == 'number' ) {
        this.cards = result.data;
        this.page = result.page;
        this.hasContentStatus();
      } else {
        this.getWallCard();
      }
    },
    /**获取卡片 */
    async getWallCard () {
      if ( throttle( this.lastOperationTime ) && this.page > 0 ) {
        this.isAnimation = -1;
        let data = { type: this.id, page: this.page, pagesize: this.pageSize, userId: this.userId, label: this.labelActive };
        await findWallPageApi( data ).then( ( res ) => {
          if ( ( res.type === 'note' && !this.id || res.type === 'photo' && this.id ) && this.page == res.current ) {
            this.cards = this.page == 1 ? res.message : this.cards.concat( res.message );
            // page处理
            res.message.length == this.pageSize ? this.page++ : this.page = 0;
            this.onSetCachedData();
            this.hasContentStatus();
          }
        } ).catch( ( error ) => {
          console.log( error );
        } );
      }
    },
    /** 存储数据 */
    onSetCachedData () {
      if ( this.id == 0 ) {
        setNotes( this.labelActive, { data: this.cards, page: this.page } );
      } else {
        setPhotos( this.labelActive, { data: this.cards, page: this.page } );
      }
    },
    /** 有无内容状态*/
    hasContentStatus () {
      if ( this.cards.length > 0 ) {
        this.isAnimation = 1;
      } else {
        this.isAnimation = 0;
      }
    },
    // 刷新
    async onRefresh () {
      this.page = 1;
      this.isRefresh = true;
      await this.getWallCard();
      window.scrollTo( 0, 0 );
      setTimeout( () => {
        this.isRefresh = false;
        this.$message( { type: 'success', message: '刷新成功~~~' } );
      }, 1000 );
    },
    /** 联系墙主撕掉该便签 */
    waitDelete () {
      this.cards[this.activeNote].cancel[0].count = 1;
    },

    /** 有举报信息 */
    haveReportInfo () {
      this.cards[this.activeNote].report[0].count = 1;
    },
    // 删除
    onAlreadyDelete ( id ) {
      this.cards = this.cards.filter( v => v.id != id );
      this.isModel = false;
      if ( this.id == 0 ) { // 留言
        this.activeNote = -1;
      } else {            // 照片
        this.isViewer = false;
      }
    },
    // 跳转到详情页
    onToItem ( item ) {
      this.news = item;
      this.onNoteClick( item );
    },
    /** 清除对应缓存 */
    onClearCache () {
      this.id == 0 ? removeNotes( this.labelActive ) : removePhotos( this.labelActive );
    },
  },
};
</script>
<style lang="less" scoped>
.wall-container {
  position: relative;
  min-height: 88%;
  text-align: center;

  .wall-scroll {
    position: relative;
    top: 0;
    min-height: 30px;
  }

  .title {
    padding-top: 15px;
    padding-bottom: @padding-8;
    font-size: 3.2vw;
    color: @gray-1;
    font-weight: 600;
  }

  .slogan {
    padding: 6px 20px;
    color: @gray-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label {
    .flex;
    flex-wrap: wrap;
    margin: 20px auto 18px;
    gap: 10px;

    .label-item {
      padding: @padding-4 18px;
      line-height: 16px;
      color: @gray-2;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-radius: 16px;
      cursor: pointer;
      transition: @tr;
      font-size: @size-14;
      font-weight: 600;
    }

    .labelActive {
      color: @gray-1;
      border: 1px solid @gray-1;
      border-radius: 16px;
      box-shadow: inset 0 1px 3px 0 @primary-color;
    }
  }
}

.note-container {
  margin: 0 auto;
  max-width: 87vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: @padding-8 @padding-8;
  cursor: pointer;
  transition: @tr;
}

.photo-container {
  max-width: 87vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-auto-rows: 2.4px; // 基础行高
  grid-column-gap: @padding-8;

  .photo-card {
    padding-top: 3px;
    margin-bottom: @padding-4;
    grid-row-end: span var(--row-span); // (占用的行数 * 基础行高) 颗粒化控制图片具体高度
  }
}

.noteActive {
  box-shadow: 0 10px 10px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}
</style>
