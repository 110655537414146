<template>
  <div class="email-container">
    <div v-if="isMobile">
      <CJGNotice :text="text"></CJGNotice>
    </div>
    <p class="title wall-title" v-if="!isMobile">时间胶囊</p>
    <p class="slogan" v-if="!isMobile">{{ text }}</p>

    <div class="operate">
      <div class="label">
        <p class="label-item " :class="labelActive == 0 && 'labelActive'" @click="onLabelActive(0)">全部</p>
        <p class="label-item" :class="labelActive == 1 && 'labelActive'" @click="onLabelActive(1)">公开</p>
      </div>
      <div class="pickup-code">
        <input class="pickup-code-input" type="search" placeholder="胶囊取件码" v-model="paramsData.pickupCode">
        <div class="pickup-code-button" @click="onSelectCapsule">取件</div>
      </div>
    </div>
    <div class="capsule-box">
      <TimeCapsuleVue v-for="(capsule, index) in capsules" :key="capsule.id" :capsule="capsule"
        :capsuleColor="_webConfig.capsuleColor" @toDetail="openModel(index)"></TimeCapsuleVue>
    </div>
    <!-- 添加与刷新 -->
    <CJGBtnAddRefresh :isRefresh="isRefresh" :addBottom="addBottom" :isModel="isModel" @onRefresh="onRefresh"
      @openModel="openModel"></CJGBtnAddRefresh>
    <!-- 加载状态 -->
    <CJGShowDateStatus :isAnimation="isAnimation" :page="paramsData.page" :length="capsules.length"></CJGShowDateStatus>
    <!-- 表单填写 -->
    <TimeCapsuleFormVue v-if="isModel" @close="onEmailCloseModel" @load="onLoad" :capsule="capsules[activeIndex]"
      :type="paramsData.type">
    </TimeCapsuleFormVue>
  </div>
</template>

<script>
export default { name: 'EmailMessage' };
</script>
<script setup>
import CJGNotice from '@/components/CJGNotice.vue';
import TimeCapsuleVue from '@/components/TimeCapsule.vue';
import CJGBtnAddRefresh from "@/components/CJGBtnAddRefresh.vue";
import CJGShowDateStatus from "@/components/CJGShowDataStatus.vue";
import TimeCapsuleFormVue from '@/components/TimeCapsuleForm.vue';
import Message from '@/components/CJG/message/message';
import eventBus from '@/utils/eventBus';
import { setCapsules, getCapsules } from '@/store/sessionStorage';
import { findCapsulesApi } from '@/api/index';
import { useStore } from 'vuex';
import { onMounted, ref, computed, inject, onUnmounted } from 'vue';
import { throttle } from '@/utils/handleFn';

let text = '一颗时光胶囊，凝聚深情与憧憬，穿梭于岁月长河。它或触达心灵深处，或飘向亲友之旁，或遇未来挚爱，或赠予未降之生。无论终点何处，皆载满爱与期望，飘向那遥远的未来。';
const store = useStore();
const { _webConfig } = inject( 'CJGindex' );
const isModel = ref( false );
const isRefresh = ref( false ); // 刷新动画状态
const addBottom = ref( store.state.isMobile ? 100 : 40 );
const capsules = ref( [] );
const activeIndex = ref( -1 ); // 选中的胶囊索引 -1为新增
const labelActive = ref( 0 ); // 0全部 1公开
const isPublicRefresh = ref( false ); // label数据刷新
const isAnimation = ref( -1 ); // 动画 -1 加载中 0 数据为空 1 无更多数据(加载完成)
const lastOperationTime = ref( 0 ); // 上次调用时间
const paramsData = ref( {
  type: 0,
  page: 1,
  pagesize: 15,
  pickupCode: '',
} );

const isMobile = computed( () => store.state.isMobile );
const modeHeight = computed( () => isMobile.value ? 200 : 250 );

// 打开空白表单弹窗
const openModel = ( index ) => {
  if ( index || index === 0 ) {
    activeIndex.value = index;
  }
  isModel.value = true;
  store.commit( 'setSystemRouteStack', { type: 1, systemRoute: 'onEmailCloseModel' } );
};

// 关闭表单弹窗
const onEmailCloseModel = () => {
  isModel.value = false;
  activeIndex.value = -1;
  store.commit( 'setSystemRouteStack', { type: 0 } );
};

// 胶囊修改后的刷新
const onLoad = ( flag ) => {
  if ( flag == 1 ) { isPublicRefresh.value = true; }
  paramsData.value.page = 1;
  findCapsulesFn();
  onEmailCloseModel();
};

// 刷新胶囊
const onRefresh = async () => {
  isRefresh.value = true;
  paramsData.value.page = 1;
  await findCapsulesFn();
  window.scrollTo( 0, 0 );
  setTimeout( () => {
    isRefresh.value = false;
    Message( { type: 'success', message: '刷新成功~~~' } );
  }, 1000 );
  onEmailCloseModel();
};

// 查找胶囊
const onSelectCapsule = () => {
  if ( paramsData.value.pickupCode !== '' ) {
    paramsData.value.page = 1;
    labelActive.value = -1;
    findCapsulesFn();
    paramsData.value.pickupCode = '';
  } else {
    Message( { message: '请输入胶囊取件码', type: 'warning' } );
  }
};

// 获取胶囊
const findCapsulesFn = async () => {
  if ( throttle( lastOperationTime.value ) && paramsData.value.page > 0 ) {
    isAnimation.value = -1;
    try {
      let res = await findCapsulesApi( paramsData.value );
      if ( Number( res.code ) === 200 ) {
        if ( paramsData.value.pickupCode !== '' ) { // 取件码
          capsules.value = res.message;
        } else {
          if ( res.type === 'capsules' && paramsData.value.page == res.current ) {
            capsules.value = paramsData.value.page == 1 ? res.message : capsules.value.concat( res.message ); // 追加数据
            res.message.length < paramsData.value.pagesize ? paramsData.value.page = 0 : paramsData.value.page++; // page处理
            onSetCachedData(); // 设置缓存
          }
        }
      }
    } catch ( err ) {
      if ( err.response.status === 401 ) {
        Message( { message: '访问该页面请先登录', type: 'error' } );
      } else if ( err.response.status === 403 ) {
        Message( { message: '令牌无效，请重新登录', type: 'error' } );
      } else {
        Message( { message: '系统错误', type: 'error' } );
      }
    } finally {
      hasContentStatus();
    }
  }
};

/** 有无内容状态*/
const hasContentStatus = () => {
  if ( capsules.value.length > 0 ) {
    isAnimation.value = 1;
  } else {
    isAnimation.value = 0;
  }
};

/** 存储数据 */
const onSetCachedData = () => {
  setCapsules( labelActive.value, { data: capsules.value, page: paramsData.value.page } );
};

/** 检查缓存 - 获取数据 */
const onCheckCachedData = () => {
  const result = getCapsules( labelActive.value );
  if ( result && Array.isArray( result.data ) && typeof result.page == 'number' ) {
    capsules.value = result.data;
    paramsData.value.page = result.page;
    hasContentStatus();
  } else {
    findCapsulesFn();
  }
};

const onLabelActive = ( index ) => {
  if ( labelActive.value === index ) return;
  labelActive.value = index;
  paramsData.value.type = index;
  paramsData.value.page = 1;
  if ( isPublicRefresh.value ) {
    findCapsulesFn();
    isPublicRefresh.value = false;
  } else {
    onCheckCachedData();
  }
};

const onScrollBottom = () => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  let clientHeight = document.documentElement.clientHeight;
  let scrollHeight = document.documentElement.scrollHeight;
  if ( isMobile.value ) {
    addBottom.value = 100;
  } else {
    if ( scrollTop + clientHeight + modeHeight.value >= scrollHeight ) {
      addBottom.value = scrollTop + clientHeight + modeHeight.value - scrollHeight;
    } else {
      addBottom.value = 40;
    }
  }
  // 加载更多卡片
  if ( Math.ceil( scrollTop + clientHeight ) + 150 >= scrollHeight ) {
    findCapsulesFn();
  }
};


onCheckCachedData();
onUnmounted( () => {
  isMobile.value && eventBus.$off( 'onEmailCloseModel', onEmailCloseModel );
} );
onMounted( () => {
  window.addEventListener( 'scroll', () => { onScrollBottom(); } );
  isMobile.value && eventBus.$on( 'onEmailCloseModel', onEmailCloseModel );
} );
</script>
<style lang="less" scoped>
.email-container {
  position: relative;
  min-height: 88%;
  text-align: center;

  .title {
    padding-top: 50px;
    padding-bottom: @padding-8;
    font-size: 3.2vw;
    color: @gray-1;
    font-weight: 600;
  }

  .slogan {
    padding: 6px 20px;
    color: @gray-2;
  }

  .capsule-box {
    margin: 0 auto;
    padding: 1vw 0;
    max-width: 87vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-gap: 10px 10px;
    cursor: pointer;
    transition: @tr;
  }

  .operate {
    .flex;
    gap: 30px;
    margin: 10px auto;

    .label {
      .flex;
      gap: 10px;

      .label-item {
        padding: @padding-4 18px;
        line-height: 16px;
        color: @gray-2;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 16px;
        cursor: pointer;
        transition: @tr;
        font-size: @size-14;
        font-weight: 600;
      }

      .labelActive {
        color: @gray-1;
        border: 1px solid @gray-1;
        border-radius: 16px;
        box-shadow: inset 0 1px 3px 0 @primary-color;
      }
    }

    .pickup-code {
      border-radius: 16px;
      overflow: hidden;
      font-size: 16px;
      position: relative;
      box-shadow: inset 0 1px 3px 0 @primary-color;


      .pickup-code-input {
        width: 300px;
        max-width: 50vw;
        padding: 5px 60px 5px 10px;
        border: 1px solid @gray-10;
        background-color: transparent;
        color: @gray-1;
      }

      .pickup-code-button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50px;
        .flex;
        background-color: @primary-color;
        color: @gray-10;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>