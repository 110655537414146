<template>
  <!-- 墙上无内容时显示 -->
  <div class="none-msg" :style="{ 'top': top + 'px' }" v-show="props.isAnimation == 0">
    <img src="@/assets/images/noNotes.png">
    <p>什么都没有，快来发布一条吧</p>
  </div>

  <!-- 加载状态 -->
  <div class="loading" v-show="props.isAnimation == -1" :style="{ 'padding-bottom': store.state.isMobile && '100px' }">
    <div id="lottie"></div>
    <p>加载中...</p>
  </div>

  <p class="bottom-tip" :style="{ 'padding-bottom': store.state.isMobile && '150px' }"
    v-if="props.page == 0 && props.length != 0 && props.isAnimation == 1">没有更多了...</p>
</template>

<script>
export default { name: 'CJGShowDataStatus' };
</script>
<script setup>
import lottie from 'lottie-web';
import loading from '@/assets/json/loading.json';
import { useStore } from 'vuex';
import { defineProps, onMounted } from 'vue';

const store = useStore();
const props = defineProps( { isAnimation: Number, page: Number, length: Number, top: { 'default': 360, 'type': Number } } );

const onLoading = () => {
  const animationContainer = document.getElementById( 'lottie' );
  if ( props.isAnimation == -1 ) {
    const params = {
      container: animationContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loading,
    };
    lottie.loadAnimation( params );
  }
};

onMounted( () => {
  onLoading();
} );
</script>
<style lang="less" scoped>
.none-msg {
  width: 96%;
  text-align: center;
  position: absolute;
  top: 360px;

  img {
    display: inline-block;
    max-height: 20vh;
  }

  p {
    font-family: serif;
    font-weight: 700;
    font-size: 24px;
    color: @gray-3;
  }
}

.loading {
  text-align: center;
  width: 100%;

  p {
    font-family: serif;
    font-weight: 700;
    font-size: 24px;
    color: @gray-3;
  }
}

#lottie {
  margin-top: 20px;
  height: 50px;
}

.bottom-tip {
  text-align: center;
  font-size: 16px;
  color: @gray-3;
  padding: 20px;
}
</style>