import { createVNode, render } from 'vue';
import CJGMessage from './CJGMessage.vue';

// 创建一个独立的容器，并渲染到 document.body 中
const messageContainer = document.createElement( 'div' );
messageContainer.classList.add( 'xtx-message-container' );
document.body.appendChild( messageContainer );

const Message = ( { message, type } ) => {
  // 创建 CJGMessage 的虚拟节点
  const comVNode = createVNode( CJGMessage, { message, type } );

  // 渲染组件到独立容器中
  render( comVNode, messageContainer );

  // 在 3 秒后隐藏组件
  setTimeout( () => {
    render( null, messageContainer );
  }, 1500 );
};

export default Message;