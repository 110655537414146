<template>
  <div class="login-container">
    <div class="main">
      <div class="top_button">
        <CJGButton medium _line class="backIndexPage" @click="onBackIndexPage">返回主页</CJGButton>
        <CJGButton medium _line class="forgetPassword" @click="toggleEditPassword">{{ !isEditPassword ? '忘记密码' : '返 回'
        }}
        </CJGButton>
      </div>
      <input type="checkbox" :checked="!toggleType" @click="onToggleType" id="chk" />

      <div class="login">
        <form class="form">
          <label for="chk" ref="loginPopup">登 陆</label>
          <input class="input" type="text" name="text" placeholder="账号" v-model="queryParamsLogin.name" />
          <div class="div-checkbox">
            <input class="input" :type="isShowPassword ? 'text' : 'password'" name="password" placeholder="密码"
              v-model="queryParamsLogin.password" />
            <div class="iconfont icon-yanjing" :style="{ 'filter': !isShowPassword ? 'grayscale(100%)' : '' }"
              @click="isShowPassword = !isShowPassword"></div>
          </div>
          <button @click="findUserLoginData" type="button">立即登录</button>
        </form>
      </div>

      <div class="register">
        <form class="form">
          <label for="chk">注 册</label>
          <input class="input" placeholder="账号：请以字母开头可包含数字下划线或点" type="text" v-model="queryParams.name"
            @change="RegExpName(queryParams.name)" />
          <input class="input" placeholder="密码：请以字母开头可包含数字下划线或点" type="text" v-model="queryParams.password"
            @change="RegExpPassword(queryParams.password)" />
          <input class="input" type="email" name="email" placeholder="邮箱" v-model="queryParams.email"
            @change="RegExpEmail(queryParams.email)" />
          <div class="div-checkbox">
            <input class="input" type="text" v-model="vCode" placeholder="验证码" />
            <div title="输入账号、密码、邮箱后可发送" class="iconfont icon-fasong"
              :style="{ filter: !isName || !isPassword || !isEmail || (vCodeTimer && vCodeTimer != -1) ? 'grayscale(100%)' : '' }"
              :disabled="!isName || !isPassword || !isEmail || (vCodeTimer && vCodeTimer != -1)"
              @click="sendVCode(queryParams.email)"></div>
          </div>
          <div class="vCode-box" v-show="vCodeTimer && vCodeTimer != -1">
            {{ vCodeTimer + '秒后可发送' }}
          </div>
          <button @click="insertUserData" :disabled="!isName || !isPassword || !isEmail" type="button"
            v-if="!isEditPassword">立即注册</button>
          <button @click="onForgetPassword" :disabled="!isName || !isPassword || !isEmail" type="button"
            v-else>确认修改</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'loginH5' };
</script>
<script setup>
import { watch, ref } from 'vue';
import { insertRegisterApi, getVerifyCodeApi, findUserLoginApi, updatePasswordApi } from '@/api/index';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { setAccessToken, setRefreshToken } from "@/store/localStorage";
import Message from '@/components/CJG/message/message';
import CJGButton from '@/components/CJGButton';
import eventBus from '@/utils/eventBus';

/** 实例化 */
const store = useStore();
const router = useRouter();
const loginPopup = ref( '' );

/** 变量 */
const toggleType = ref( true ); // 切换登录与注册
const isEditPassword = ref( false ); // 切换修改密码
const vCode = ref( '' ); // 验证码
const isEmail = ref( false ); // 邮箱
const isPassword = ref( false ); // 密码
const isName = ref( false ); // 账号
const vCodeTimer = ref( -1 ); // 验证码计时器
const isShowPassword = ref( false ); // 是否显示密码
const queryParams = ref( {
  // 用户注册请求数据
  name: '',
  password: '',
  email: '',
  ip: '',
  moment: new Date(),
  competence: 1,
  vCode: '',
  avatar: '',
  nickname: ''
} );
const queryParamsLogin = ref( {
  // 用户注册请求数据
  name: '',
  password: ''
} );
const theme = ref( '' ); // 主题

/** 计算属性 */
watch(
  () => store.state.theme,
  ( nv ) => {
    theme.value = nv;
    document.body.setAttribute( 'data-theme', nv );
  },
  { immediate: true }
);

/** 函数 */
const insertUserData = async () => {
  if ( !vCode.value ) return;
  queryParams.value.ip = store.state.ip;
  queryParams.value.vCode = vCode.value;
  await insertRegisterApi( queryParams.value )
    .then( ( res ) => {
      Message( {
        type: res.code == 200 ? 'success' : 'warning',
        message: res.message
      } );
      if ( res.code == 200 ) {
        loginPopup.value.click();
        queryParamsLogin.value.name = queryParams.value.name;
        queryParamsLogin.value.password = queryParams.value.password;
      }
    } )
    .catch( () => {
      Message( { type: 'error', message: '验证条件失败' } );
      setTimeout( () => router.go( 0 ), 1000 );
    } );
};

// 登录
const findUserLoginData = async () => {
  if ( queryParamsLogin.value.name && queryParamsLogin.value.password ) {
    await findUserLoginApi( queryParamsLogin.value ).then( ( res ) => {
      Message( { type: 'success', message: '登录成功' } );
      setAccessToken( res.accessToken );
      setRefreshToken( res.refreshToken );
      store.commit( 'setIp', res.message.ip );
      router.replace( { path: '/' } );
      eventBus.$emit( 'clearSystemRouteStack' );
    } ).catch( () => {
      Message( { type: 'error', message: '账号或密码错误' } );
    } );
  }
};

// 修改密码
const onForgetPassword = async () => {
  if ( !vCode.value ) return;
  queryParams.value.vCode = vCode.value;
  await updatePasswordApi( {
    name: queryParams.value.name,
    password: queryParams.value.password,
    email: queryParams.value.email,
    vCode: queryParams.value.vCode
  } ).then( ( res ) => {
    Message( { type: res.code == 200 ? 'success' : 'warning', message: res.message } );
    if ( res.code == 200 ) {
      toggleEditPassword();
      queryParamsLogin.value.name = queryParams.value.name;
      queryParamsLogin.value.password = queryParams.value.password;
    }
  } ).catch( () => {
    Message( { type: 'error', message: '验证条件失败' } );
    setTimeout( () => router.go( 0 ), 1000 );
  } );
};

// 发送验证码
const sendVCode = async () => {
  if ( !isEmail.value || !isPassword.value || !isName.value ) return;
  await getVerifyCodeApi( {
    email: queryParams.value.email
  } )
    .then( ( res ) => {
      Message( { type: 'success', message: '验证码已发送' } );
      startTimer();
    } )
    .catch( ( err ) => {
      console.log( err );
      Message( { type: 'error', message: '小忆梦游中' } );
    } );
};

// 验证码倒计时
const startTimer = () => {
  let time = 120;
  const timer = setInterval( () => {
    if ( time == 0 ) {
      clearInterval( timer );
      vCodeTimer.value = false;
      return;
    }
    vCodeTimer.value = time;
    time--;
  }, 1000 );
};

const RegExpEmail = ( email ) => {
  const reg = new RegExp( '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$' ); //正则表达式
  if ( !reg.test( email.trim() ) ) {
    isEmail.value = false;
    Message( { type: 'error', message: '请输入正确的邮箱地址' } );
  } else {
    isEmail.value = true;
  }
};

const RegExpPassword = ( password ) => {
  const reg = new RegExp( '^[a-zA-Z]{1}([a-zA-Z0-9]|[._]){4,19}$' ); //正则表达式
  if ( !reg.test( password.trim() ) ) {
    isPassword.value = false;
    Message( { type: 'error', message: '格式错误' } );
  } else {
    isPassword.value = true;
  }
};

const RegExpName = ( name ) => {
  const reg = new RegExp( '^[a-zA-Z]{1}([a-zA-Z0-9]|[._]){4,19}$' ); //正则表达式
  if ( !reg.test( name.trim() ) ) {
    isName.value = false;
    Message( { type: 'error', message: '格式错误' } );
  } else {
    isName.value = true;
  }
};
// 切换登录与注册
const onToggleType = () => {
  toggleType.value = !toggleType.value;
  isEditPassword.value = false;
};
// 返回首页
const onBackIndexPage = () => {
  router.replace( { path: '/' } );
  eventBus.$emit( 'clearSystemRouteStack' );
};
// 修改密码
const toggleEditPassword = () => {
  toggleType.value = !toggleType.value;
  isEditPassword.value = !isEditPassword.value;
  queryParams.value.email = '';
  queryParams.value.vCode = '';
  vCode.value = '';
};

/** 钩子 */
</script>
<style lang="less" scoped>
.login-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  background-color: @gray-9;
  .flex;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  height: 100%;
  width: 100%;
  overflow: hidden;
  // border-radius: 3vw;
  // box-shadow: 7px 7px 10px 3px #24004628;
}

.top_button {
  position: absolute;
  top: 20px;
  width: 90%;
  margin: 0 5%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .backIndexPage,
  .forgetPassword {
    width: unset;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 7vw;
  padding: 6vw;
  padding-bottom: 35%;
}

/*checkbox to switch from sign up to login*/
#chk {
  display: none;
}

/*Login*/
.login {
  position: relative;
  width: 100%;
  height: 100%;
}

.login label {
  margin: 25% 0 5%;
  text-shadow: 4px 4px 5px #0000003b;
}

label {
  .flex;
  color: @gray-10;
  font-size: 14vw;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.div-checkbox {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;

  span {
    font-size: 12px;
    color: #cfcfcf;
    text-transform: uppercase;
  }

  .icon-fasong {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5vw;
    width: 6.5vw;
    font-size: 6.5vw;
    color: @success-color;
  }

  .icon-yanjing {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5vw;
    width: 6.5vw;
    font-size: 6.5vw;
    color: @success-color;
  }
}

.input {
  width: 100%;
  font-size: 4vw;
  padding: 5vw;
  box-sizing: border-box;
  border: none;
  outline: none;
  height: 100%;
  border-radius: 1vw;
  box-shadow: 16px 16px 5px rgba(0, 0, 0, 0.1);
}

/*Register*/
.register {
  background: @gray-9;
  border-radius: 60% / 10%;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  transform: translateY(10%);
  transition: 0.8s ease-in-out;
  box-shadow: 0 30px 100px 20px rgba(0, 0, 0, 0.1);
}

.register label {
  color: #000;
  transform: scale(0.6);
}

#chk:checked~.register {
  transform: translateY(-60%);
}

#chk:checked~.register label {
  transform: scale(1);
  margin: 10% 0 5%;
}

#chk:checked~.login label {
  transform: scale(0.6);
  margin: 5% 0 5%;
}

/*Button*/
.form button {
  width: 100%;
  font-size: 4vw;
  padding: 5vw;
  box-sizing: border-box;
  margin: 7.5vw auto 10%;
  color: @gray-10;
  background: #22bf87;
  font-weight: bold;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  transition: 0.2s ease-in;
  box-shadow: 16px 16px 5px rgba(6, 100, 66, 0.1);
}

.form button:hover {
  background-color: #34b688;
}

.vCode-box {
  font-size: 3vw;
  color: @gray-2;
  transition: @tr;
}
</style>
