<template>
  <div class="foot-friendly-link" :style="{ 'padding': mobile && 0 }">
    <div class="link-title" v-if="!mobile">友情链接</div>
    <div class="link-box"
      v-if="webConfig && Array.isArray(webConfig.friendlyLink) && webConfig.friendlyLink.length > 0">
      <template v-for="(item, index) in webConfig.friendlyLink" :key="item.name">
        <div class="link-text" v-if="active === index" @click="handleLink(item.link)">
          <img v-if="item.image != ''" :src="showImage(item.image)" :alt="item.name" class="link-img">
          <div class="link-name">{{ item.title }}</div>
        </div>
      </template>
    </div>
    <div class="setting">
      <i class="iconfont icon-shezhi1" @click="toggleColorPopup"></i>
    </div>
  </div>
</template>

<script>
export default { name: 'FriendlyLink' };
</script>
<script setup>
import { ref, defineProps, inject } from 'vue';
import { baseUrl } from "@/utils/env";
import eventBus from '@/utils/eventBus';

const { webConfig } = inject( 'CJGindex' );
defineProps( { mobile: { type: Boolean, default: false } } );

const active = ref( 0 );

let oldDate = '';
const imgCacheMap = new Map();
const showPictureType = '/friendLogo/';

const showImage = ( url ) => {
  if ( imgCacheMap.has( url ) ) return imgCacheMap.get( url );

  try {
    const requestUrl = `${ baseUrl }/api/commonPicture?url=${ encodeURIComponent( url ) }&type=${ showPictureType }`;
    // 利用浏览器的HTTP缓存
    fetch( requestUrl, { cache: 'force-cache' } );

    imgCacheMap.set( url, requestUrl );
    return requestUrl;
  } catch ( error ) {
    console.error( 'Failed to load image:', error );
  }
};

const handleLink = ( link ) => {
  if ( window.plus ) {
    window.plus.runtime.openURL( link );
  } else {
    window.open( link, '_blank' );
  }
};

const activeAdd = () => {
  if ( oldDate === '' || ( new Date().getTime() - oldDate ) > 5000 ) {
    active.value = active.value + 1 > ( webConfig && Array.isArray( webConfig.friendlyLink ) && webConfig.friendlyLink.length - 1 ) ? 0 : active.value + 1;
    oldDate = new Date().getTime();
  }
  requestAnimationFrame( activeAdd );
};

const toggleColorPopup = () => {
  eventBus.$emit( 'openColorPopup' );
};
// 使用requestAnimationFrame函数 递归调用，比定时器节省性能
requestAnimationFrame( activeAdd );
</script>
<style lang="less" scoped>
.foot-friendly-link {
  width: 100%;
  margin: 0 auto;
  padding: 20px 30px 0 30px;
  box-sizing: border-box;
  .flex-s;

  .link-title {
    max-width: 80px;
    display: inline-block;
    font-size: 18px;
  }

  .link-box {
    max-width: 60%;
    display: inline-block;
    margin-left: 20px;

    .link-text {
      .flex-s;
      gap: 6px;

      .link-img {
        width: 30px;
        overflow: hidden;
      }

      .link-name {
        width: 60vw;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .setting {
    margin-left: auto;
    margin-right: calc(1.5vh + 0.5vw);
    cursor: pointer;

    .iconfont {
      font-size: 20px;
    }
  }
}
</style>