<template>
  <div class="member-detail-container">
    <img class="avatar-item" :src="memberInfo.avatar">
    <div class="info-box" :style="{ 'top': props.top + 'px', 'left': props.left + 'px' }"
      v-if="memberInfo.id == props.memberActiveId" @click.stop>
      <i class="iconfont icon-guanbi" @click="closeModel"></i>
      <div class="row">
        <img class="avatar" :src="memberInfo.avatar">
      </div>
      <div class="row">
        <div class="left">昵称:</div>
        <div class="right">{{ memberInfo.name }}</div>
      </div>
      <div class="row">
        <div class="left">状态:</div>
        <div class="right">{{ memberInfo.status == 0 ? '正常' : '禁言' }}</div>
      </div>
      <div class="row">
        <div class="left">加入时间:</div>
        <div class="right">{{ formatDate(memberInfo.createTime) }}</div>
      </div>
      <div class="row btn" v-if="isRoomAdmin && props.roomUserId != memberInfo.userId" @click.stop>
        <CJGButton small line @click="onEditMemberStatus">{{ memberInfo.status == 0 ? '禁言' : '解禁' }}</CJGButton>
        <CJGButton small line v-if="!confirmKickOut" @click="confirmKickOut = true">踢出</CJGButton>
        <CJGButton small line v-else @click="onConfirmKickOut">确认</CJGButton>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'MemberDetail' };
</script>
<script setup>
import CJGButton from '@/components/CJGButton.vue';
import { formatDate } from '@/utils/handleFn';
import { defineProps, defineEmits, computed, ref } from 'vue';

const emit = defineEmits( ['close', 'kickOut', 'silenceClient'] );
const props = defineProps( {
  'memberInfo': { 'type': Object, default: {} },
  'memberActiveId': { 'type': Number, default: -1 },
  'top': { 'type': Number, default: 0 },
  'left': { 'type': Number, default: 0 },
  'isLordAvatar': { 'type': Boolean, default: false },
  'isRoomAdmin': { 'type': Boolean, default: false },
  'roomUserId': { 'type': Number, default: null },
} );

const confirmKickOut = ref( false );
const memberInfo = computed( () => {
  return props.memberInfo;
} );

const closeModel = () => {
  emit( 'close' );
};

const onConfirmKickOut = () => {
  emit( 'kickOut' );
};

const onEditMemberStatus = () => {
  emit( 'silenceClient', memberInfo.value.userId, memberInfo.value.status );
  closeModel();
};
</script>
<style lang="less" scoped>
.member-detail-container {
  position: relative;
  width: 12vmin;
  height: 12vmin;
  max-width: 50px;
  max-height: 50px;
  background-color: @gray-2-6;
  border-radius: 50%;
  color: @gray-10;
  .flex;

  .avatar-item {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .info-box {
    position: fixed;
    z-index: 1;
    background-color: @gray-10-6;
    backdrop-filter: blur(10px);
    border: 1px solid @gray-10-8;
    box-shadow: 5px 5px 15px @gray-1-2;
    padding: @padding-20;
    border-radius: 5px;
    color: @gray-1;
    .flex-s;
    flex-direction: column;
    gap: 10px;

    .row {
      width: 100%;
      .flex-s;
      gap: 4px;

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

      .right {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .btn {
      margin-top: 30px;
    }

    .icon-guanbi {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

}
</style>