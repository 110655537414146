<template>
  <div class="notice-container">
    <div class="swiper" v-show="text">
      <div class="swiper-item">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default { name: 'CJGNotice' };
</script>
<script setup>
import { defineProps } from 'vue';
defineProps( { text: String } );
</script>
<style lang="less" scoped>
.notice-container {
  width: 100%;
  padding: 0 20px;
  max-height: 30px;
  overflow: hidden;
  box-sizing: border-box;

  .swiper {
    overflow-x: auto;

    .swiper-item {
      font-size: 2.8vw;
      padding: 8px 0;
      transition: @tr;
      color: @gray-2;
      white-space: nowrap;
      animation: noticeScroll 30s linear infinite;
    }
  }
}

@keyframes noticeScroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-260%);
  }
}
</style>