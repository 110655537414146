import { createVNode, render } from 'vue';
import CJGShowDialog from './CJGShowDialog.vue';

// 创建一个独立的容器，并渲染到 document.body 中
const dialogContainer = document.createElement( 'div' );
dialogContainer.classList.add( 'xtx-dialog-container' );
document.body.appendChild( dialogContainer );

/**  
* 显示弹窗的函数  
* @param {Object} options - 弹窗的选项  
* @param {string} options.currentType - 自定义类型
* @param {string} options.type - 盒子大小类型
* @param {string} options.title - 标题  
* @param {string} options.message - 内容  
* @param {boolean} options.input - 是否显示输入框  
* @param {boolean} options.cancel - 是否显示取消按钮  
* @param {boolean} options.confirm - 是否显示确认按钮  
* @param {Function} options.onCancel - 取消按钮的回调函数  
* @param {Function} options.onConfirm - 确认按钮的回调函数  
*/
const showDialog = ( { currentType, type, title, message, input, cancel, confirm = true, onCancel, onConfirm } ) => {
  // 创建 CJGShowDialog 的虚拟节点
  const dialogVNode = createVNode( CJGShowDialog, {
    currentType, type, title, message, input, cancel, confirm,
    'onCancel': () => {
      typeof onCancel === 'function' && onCancel();
      // 隐藏组件
      render( null, dialogContainer );
    },
    'onConfirm': ( value ) => {
      typeof onConfirm === 'function' && onConfirm( input ? value : 'confirm' );
      // 隐藏组件
      render( null, dialogContainer );
    }
  } );

  // 渲染组件到独立容器中
  render( dialogVNode, dialogContainer );
};
export default showDialog;