import Message from '@/components/CJG/message/message';

/** 节流 函数 */
export const throttle = ( lastOperationTime, timeInterval = 300 ) => {
  const now = Date.now();
  const lastCall = lastOperationTime || 0;
  if ( now - lastCall < timeInterval ) {
    return false;
  } else {
    lastOperationTime = now; // 更新上次调用时间  
    return true;
  }
};
/** 处理时间函数 */
export const formatDate = ( e ) => {
  let date = new Date( e );
  let Y = date.getFullYear();
  let M = date.getMonth() + 1;
  let D = date.getDate();
  D = D < 10 ? '0' + D : D;
  M = M < 10 ? '0' + M : M;
  return Y + '-' + M + '-' + D;
};

/** 获取图片数据 */
export const getObjectUrl = ( e ) => {
  let url = null;
  if ( window.createObjectURL != undefined ) { // basic
    url = window.createObjectURL( e );
  } else if ( window.URL != undefined ) { // mozilla(firefox) IE
    url = window.URL.createObjectURL( e );
  } else if ( window.webkitURL != undefined ) { // Safari chrome
    url = window.webkitURL.createObjectURL( e );
  }
  return url;
};

/** base64 转换为 字符串 */
export const base64ToString = ( content ) => {
  const decoder = new TextDecoder( 'utf-8' ); // 创建一个文本解码器
  const bytes = atob( content ).split( '' ).map( c => c.charCodeAt( 0 ) ); // 将 base64 字符串转换为字节数组
  const uint8Array = new Uint8Array( bytes ); // 创建一个 Uint8Array 对象
  return decoder.decode( uint8Array ); // 将 Uint8Array 对象转换为字符串
};

/** 获取token负载 */
export const getTokenPayload = ( token ) => {
  if ( token ) {
    let base64Url = token.split( '.' )[1];
    const base64 = base64Url.replace( /-/g, '+' ).replace( /_/g, '/' );
    const decoded = decodeURIComponent( atob( base64 ).split( '' ).map( function ( c ) {
      return '%' + ( '00' + c.charCodeAt( 0 ).toString( 16 ) ).slice( -2 );
    } ).join( '' ) );
    const payload = JSON.parse( decoded );
    return payload && payload.result;
  }
  return null;
};

/** 复制 */
export const onCopyRoomKey = async ( text, successMessage = '复制成功！' ) => {
  if ( navigator.clipboard && !window.plus ) {
    await navigator.clipboard.writeText( text ).then( () => {
      Message( { message: successMessage, type: 'success' } );
    } ).catch( err => {
      Message( { message: '复制失败，请手动复制', type: 'error' } );
      console.error( '复制失败: ', err );
    } );
  } else if ( window.plus && window.plus.os.name === 'iOS' ) {
    try {
      const UIPasteboard = window.plus.ios.import( "UIPasteboard" );
      const generalPasteboard = UIPasteboard.generalPasteboard();
      generalPasteboard.setValueforPasteboardType( text, "public.utf8-plain-text" );
      Message( { message: successMessage, type: 'success' } );
    } catch ( err ) {
      Message( { message: '环境不支持，请手动复制', type: 'error' } );
    }
  } else if ( window.plus && window.plus?.os.name === 'Android' ) {
    try {
      const Context = window.plus.android.importClass( "android.content.Context" );
      const ClipData = window.plus.android.importClass( "android.content.ClipData" );
      const main = window.plus.android.runtimeMainActivity();
      const clipboard = main.getSystemService( Context.CLIPBOARD_SERVICE );
      window.plus.android.importClass( clipboard );
      const clip = ClipData.newPlainText( "text", text );
      clipboard.setPrimaryClip( clip );
      Message( { message: successMessage, type: 'success' } );
    } catch ( err ) {
      console.log( err );
      Message( { message: '环境不支持，请手动复制', type: 'error' } );
    }
  } else {
    // 对于不支持clipboard API的浏览器，可以使用旧的方法，如创建一个临时的textarea元素
    const textArea = document.createElement( 'textarea' );
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild( textArea );
    textArea.focus();
    if ( textArea.setSelectionRange ) {
      textArea.setSelectionRange( 0, textArea.value.length );
    } else {
      textArea.select();
    }
    try {
      const successful = document.execCommand( 'copy' );
      if ( successful ) {
        Message( { message: successMessage, type: 'success' } );
      } else {
        Message( { message: '环境不支持，请手动复制', type: 'error' } );
      }
    } catch ( err ) {
      Message( { message: '环境不支持，请手动复制', type: 'error' } );
      console.error( '复制失败: ', err );
    }
    document.body.removeChild( textArea );
  }
};