/**
 * 根据环境变量配置参数类型
 * 
 * baseUrl： 域名地址
 * shareUrl：分享地址
 * routerMode：路由地址
 * baseImgPath: 图片上传地址
 */
let baseUrl = '';
let shareUrl = '';
let routerMode = 'hash';
let baseImgPath;

if ( typeof process !== 'undefined' || typeof window !== 'undefined' ) { // nodeJS环境
    if ( process.env.NODE_ENV === 'development' ) {
        baseUrl = 'http://localhost:9001';
        shareUrl = 'http://localhost:8001';
        baseImgPath = 'http://localhost:9001';
    } else {
        baseUrl = 'https://www.1.lxlike.top';
        shareUrl = 'https://www.lxlike.top';
        baseImgPath = 'https://www.1.lxlike.top';
    }
}

export {
    baseUrl,
    shareUrl,
    routerMode,
    baseImgPath
};