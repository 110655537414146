<template>
  <div class="new-card">
    <div class="colors" v-if="id == 0">
      <p :style="{ background: pieceColor[index] }" class="color-li" :class="{ 'color-active': index === activeIndex }"
        v-for="(item, index) in pieceColor" :key="index" @click="onColorClick(index)"></p>
    </div>
    <!-- 照片 -->
    <div class="add-photo" v-if="id == 1">
      <input type="file" name="file" id="file" @change="handlePhoto">
      <div class="add-bt" v-if="url == ''">
        <span class="iconfont icon-tianjia"></span>
      </div>
      <div class="change-bt" v-if="url != ''">
        <span class="iconfont icon-yumaobi"></span>
      </div>
      <div class="photo-div"><img :src="url"></div>
    </div>
    <!-- 留言 -->
    <div class="card-main" :style="{ background: id == 0 ? cardColor[activeIndex] : cardColor[5] }">
      <textarea placeholder="留言..." class="message" v-model="message"></textarea>
      <div class="author_count">
        <span class="count" v-if="message.length > 100"
          v-text="message.length > 2000 ? '内容超出' : `${message.length}/2000`"></span>
        <input type="text" placeholder="签名" class="author" :disabled="isNickNameEdit" v-model="author" />
      </div>
    </div>
    <div class="labels">
      <p class="title">选择标签</p>
      <p class="label-item" :class="{ 'label-item-active': labelActive === -1 }" @click="onLabelClick(-1)">
        全部
      </p>
      <p class="label-item" v-for="(item, index) in labels[id]" :key="index"
        :class="{ 'label-item-active': labelActive === index }" @click="onLabelClick(index)">
        {{ item }}
      </p>
    </div>
    <div class="illustrate">
      <div class="title">免责声明</div>
      <p>
        长卷阁由本人开发的，为便于与用户交流的留言平台。请不要利用此平台服务制作、上传、下载、复制、发布、传播或者转载如下内容：
      </p>
      <p>1、反对宪法所确定的基本原则的</p>
      <p>2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
      <p>3、损害国家荣誉和利益的；</p>
      <p>4、煸动民族仇恨、民族歧视，破坏民族团结的</p>
      <p>5、破坏国家宗教政策，宣扬邪教和封建迷信的</p>
      <p>6、散布谣言，扰乱社会秩序，破坏社会稳定的</p>
      <p>7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的</p>
      <p>8、侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>9、含有法律、行政法规禁止的其他内容的信息。</p>
    </div>

    <div class="foot-btn">
      <CJGButton class="discard" line large @click="onCloseModel(0)">丢弃</CJGButton>
      <CJGButton class="confirm" flat large @click="onSubmit">确认</CJGButton>
    </div>
  </div>
</template>

<script>
import { pieceColor, cardColor, labels } from "@/utils/data";
import { getObjectUrl } from "@/utils/handleFn";
import CJGButton from "./CJGButton.vue";
import { insertWallApi, uploadImgApi } from "@/api";

export default {
  components: {
    CJGButton,
  },
  props: {
    id: {
      default: 0,
    },
  },
  data () {
    return {
      cardColor, // mock背景颜色
      pieceColor, // mock颜色
      labels, // mock标签
      activeIndex: 0, // 颜色选中Id
      labelActive: -1, // 标签选中Id
      message: "", // 留言内容
      author: this.$store.state.user?.nickname || "", // 签名
      isNickNameEdit: this.$store.state.user?.nickname != null, // 是否修改昵称
      userId: this.$store.state.user?.id || this.$store.state.ip, // 用户id
      url: '', // 图片地址
    };
  },
  mounted () {
    document.body.style.overflowY = 'hidden';
  },
  unmounted () {
    document.body.style.overflowY = 'auto';
  },
  methods: {
    handlePhoto ( e ) {
      let file = e.target?.files[0];
      let regular = /^image\/(jpeg|jpg|png|gif)$/;
      let fileSize = ( file?.size / 1024 / 1024 ).toFixed( 2 );
      if ( fileSize <= 8 && regular.test( file.type ) ) {
        this.url = getObjectUrl( document.getElementById( "file" ).files[0] );
      } else {
        this.$message( { type: 'warning', message: `图片大小不可超过8MB<br>且文件格式为jpeg、jpg、png、gif` } );
      }
    },
    onCloseModel ( data ) {
      this.$emit( "onHandleClose", data );
    },
    onColorClick ( index ) {
      this.activeIndex = index;
    },
    onLabelClick ( index ) {
      this.labelActive = index;
    },
    /** 发起请求 - 新建wall 留言 */
    onSubmit () {
      let author = this.author ? this.author : "匿名";
      let data = {
        type: this.id,
        message: this.message,
        name: author,
        userId: this.userId,
        label: this.labelActive,
        moment: new Date(),
        color: 5,
        imgurl: this.url ? this.url : '',
      };
      // id: 0为留言 1为照片
      if ( this.message.trim().length < 8 ) {
        this.$message( { type: 'warning', message: '留言内容不可少于8个字符' } );
      } else if ( this.message.trim().length > 2000 ) {
        this.$message( { type: 'warning', message: '留言内容不可多于2000个字符' } );
      } else {
        if ( this.id == 0 ) {
          data.color = this.activeIndex;
          this.onSendNoteApi( data );
        } else if ( this.id == 1 ) {
          if ( this.url != '' ) {
            this.upload( data );
          } else {
            this.$message( { type: 'warning', message: '请选择图片' } );
          }
        }
      }
    },

    /** 图片提交 */
    async upload ( data ) {
      const file = document.getElementById( 'file' );
      if ( file.files.length > 0 ) {
        // 注意: FormData 会自动在请求头中添加"Content-Type, 
        // 如果封装请求实例时 设置了"Content-Type", 会自动覆盖这里请求头中的"Content-Type"，导致请求失败。
        let formData = new FormData(); // 创建一个空的表单数据
        formData.append( 'file', file.files[0] ); // 添加数据
        await uploadImgApi( formData ).then( ( response ) => {
          data.imgurl = response;
          this.onSendNoteApi( data );
        } );
      }
    },

    /** 发送留言提交的接口 */
    async onSendNoteApi ( data ) {
      await insertWallApi( data ).then( () => {
        this.$emit( 'onSubmit' );
        this.url = '';
        this.$message( { type: 'success', message: '感谢你留下足迹' } );
      } ).catch( () => {
        this.$message( { type: 'error', message: '您没有发布成功呦' } );
      } );
    }

  },
};
</script>
<style lang="less" scoped>
.new-card {
  margin-left: @padding-20;
  margin-right: @padding-20;

  .colors {
    .flex-s;
    height: 100%;
    flex-wrap: wrap;
    gap: @padding-8;

    .color-li {
      width: 28px;
      height: 28px;
      box-sizing: border-box;
      border-radius: 5%;
      cursor: pointer;
      transition: @tr;

      &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      }
    }

    .color-active {
      border: 1px solid #0570fc;
    }
  }

  .card-main {
    width: 100%;
    min-height: 240px;
    background-color: pink;
    margin-top: @padding-12;
    padding: @padding-12;
    box-sizing: border-box;
    text-align: left;

    .message {
      background: none;
      border: none;
      width: 100%;
      resize: none;
      min-height: 172px;
      // font-family: qiantuxianmoti;
    }

    .author_count {
      width: 100%;
      .flex;
    }

    .author {
      flex: 1;
      box-sizing: border-box;
      padding: @padding-8;
      background: none;
      border: 1px solid #fff;
      line-height: 20px;
      // font-family: qiantuxianmoti;
      font-size: 15px;
      text-align: right;
    }

    .count {
      flex: 1;
      padding: @padding-4;
      line-height: 20px;
      font-size: 14px;
    }

    .message::placeholder,
    .author::placeholder {
      color: @gray-2;
      // font-family: qiantuxianmoti;
      font-size: 15px;
    }
  }

  .labels {
    text-align: left;
    margin-top: 30px;

    .title {
      font-weight: 600;
      color: @gray-1;
    }

    .label-item {
      padding: 2px 10px;
      border-radius: 15px;
      float: left;
      margin: 15px 8px 5px 0;
      font-size: 12px;
      cursor: pointer;
      transition: @tr;

      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }

    .label-item-active {
      background-color: @gray-0-6;
      color: @gray-10;
    }
  }

  .illustrate {
    padding-top: 120px;
    padding-bottom: 60px;
    text-align: left;

    p {
      font-size: 12px;
      color: @gray-2;
    }

    .title {
      font-weight: 600;
      margin-bottom: 4px;
    }
  }

  .foot-btn {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: @padding-20;
    box-sizing: border-box;
    .flex-b;

    .confirm {
      width: 62%;
    }
  }

  .add-photo {
    padding-bottom: @padding-20;
    position: relative;

    #file {
      position: absolute;
      z-index: 10;
      top: -10px;
      left: 0;
      height: 74px;
      width: 64px;
      opacity: 0;
      cursor: pointer;
    }

    .add-bt {
      width: 64px;
      height: 64px;
      border: 1px solid @gray-3;
      border-radius: 32px;
      cursor: pointer;
      .flex;

      .icon-tianjia {
        font-size: 24px;
      }
    }

    .photo-div {
      max-height: 200px;
      width: 100%;
      background: @gray-9;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .change-bt {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      cursor: pointer;
      .flex;

      .icon-yumaobi {
        color: @gray-10;
      }
    }
  }
}
</style>
