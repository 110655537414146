<template>
  <transition name="down">
    <div class="message-box" v-if="isShow">
      <div class="message">
        <i class="iconfont" :class="style[type].icon"></i>
        <span class="text" v-html="message"></span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CJGMessage",
  props: {
    message: {
      default: "",
      type: String,
    },
    type: {
      default: "warning", //warning警告、error错误、success成功
      type: String,
    },
  },

  data () {
    return {
      style: {
        warning: {
          icon: "icon-warning",
        },
        error: {
          icon: "icon-icon_error",
        },
        success: {
          icon: "icon-icon_success",
        },
      },
      isShow: false,
    };
  },
  methods: {},
  mounted () {
    this.isShow = true;
  },

};
</script>
<style lang="less" scoped>
// 动画效果
.down {
  &-enter {
    &-from {
      opacity: 0;
      transform: translateY(-50px);
    }

    &-active {
      transition: @tr;
    }

    &-to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-leave {
    &-from {
      opacity: 1;
      transform: translateY(0);
    }

    &-active {
      transition: all @tr;
    }

    &-to {
      opacity: 0;
      transform: translateY(50px);
    }
  }
}

.message-box {
  width: auto;
  height: 40px;
  position: fixed;
  z-index: 9999;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 42px;
  .flex;

  .message {
    width: auto;
    max-width: 500px;
    padding: 0 20px;
    border-radius: 4px;
    color: @gray-1;
    background-color: @gray-10;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    display: flex;

    i {
      margin-right: 6px;
      vertical-align: middle;
    }

    .text {
      white-space: nowrap;
      vertical-align: middle;
    }
  }
}

.icon-icon_success {
  color: @success-color;
}

.icon-icon_error {
  color: @error-color;
}

.icon-warning {
  color: @warning-color;
}
</style>