<template>
  <transition name="model" @click.stop>
    <div class="CJG-model" :style="occupied ? 'top:0;height:100%;' : ''" ref="modelRef" v-if="isModel">
      <div class="CJG-model-head">
        <p class="model-name">{{ title }}</p>
        <div class="CJG-model-head-right">
          <span title="复制链接" class="iconfont icon-fenxiang1" @click.stop="copyShareUrl" v-if="activeNote != -1"></span>
          <span title="关闭" class="iconfont icon-guanbi" @click.stop="onCloseModel" @close="close"></span>
        </div>
      </div>
      <div class="main" @click.stop>
        <slot class="slot"></slot>
      </div>
    </div>
  </transition>
</template>
<script setup>
import { ref, defineProps, defineEmits, onUnmounted, watch, onMounted } from "vue";
import { onCopyRoomKey } from "@/utils/handleFn";

const modelRef = ref( '' );
const props = defineProps( {
  title: {
    default: "标题",
  },
  isModel: {
    default: false,
  },
  occupied: {
    default: false,
    type: Boolean,
  },
  articleUrl: {
    default: '',
    type: String,
  },
  activeNote: {
    default: -1,
    type: Number,
  }
} );

const emit = defineEmits( ["close"] );
const onCloseModel = () => {
  emit( "close" );
};

watch( () => props.isModel, ( nv ) => {
  if ( !nv ) { return; }
  setTimeout( () => {
    modelRef.value.addEventListener( 'touchmove', e => e.stopPropagation(), false );
  }, 10 );
}, );

const copyShareUrl = () => {
  onCopyRoomKey( props.articleUrl, '分享链接已复制！' );
};

onMounted( () => {

} );
onUnmounted( () => {
  onCloseModel;
} );
</script>
<style lang="less" scoped>
.CJG-model {
  position: fixed;
  top: 60px;
  right: 0;
  width: 360px;
  height: calc(100% - 60px);
  background-color: @gray-10-8;
  backdrop-filter: blur(50px);
  box-shadow: 0 0 12px 4px @gray-0-2;
  z-index: 90;
  box-sizing: border-box;

  .CJG-model-head {
    .flex-b;
    padding: @padding-20;
    padding-bottom: 10px;

    .model-name {
      font-size: @size-16;
      color: @gray-1;
      font-weight: 550;
    }

    .icon-guanbi,
    .icon-fenxiang1 {
      color: @gray-2;
      cursor: pointer;
      padding-left: 8px;
    }

    .icon-fenxiang1 {
      padding-right: 8px;
    }
  }

  .main {
    height: calc(100% - 54px - 80px);
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    .slot {
      height: 900px;
      margin-left: @padding-20;
      margin-right: @padding-12;
      box-sizing: border-box;
      text-align: left;
    }
  }
}

.model {

  // 入场动画
  &-enter {
    &-from {
      transform: translateX(360px);
      // opacity: 1;
    }

    &-active {
      transition: all 0.3s ease-out;
    }

    &-to {
      transform: translateX(0px);
      // opacity: 0.6;
    }
  }

  &-leave {
    &-from {
      transform: translateX(0px);
      // opacity: 1;
    }

    &-active {
      transition: all 0.3s ease-in;
    }

    &-to {
      transform: translateX(360px);
      // opacity: 0.3;
    }
  }
}
</style>
